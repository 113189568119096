export default {
  seo: {
      title: 'FAQ | Bank 131 FAQ',
      description: 'Answering the most common questions'
  },
  title: 'FAQ',
  listFaq: [
    {
      title: '1. «Мой налог»: Instructions for linking Bank 131',
      rectangle: [
        {
          description: `<p>To connect Bank 131 to «Мой налог», follow the instructions below:</p>

          <p>1. Open your personal account «Мой налог» on the website or in the application, go to the <strong>«Настройки»</strong> section, the <strong>«Партнеры»</strong> tab.</p>

          <p>
            <picture>
              <source media="(max-width: 1064px)" srcset="/images/FAQ_1.jpg">
              <img alt="" src="/images/FAQ_1.jpg" srcset="/images/FAQ_1.jpg 1x, /images/FAQ_1@2x.jpg 2x" width="100%">
            </picture>
          </p>

          <p>2. In the «Партнеры» tab, find Bank 131 and click the <strong>«Предлагает подключиться»</strong> button.</p>

          <p><em>If there is no button, contact the company that makes payments to you, with a request to send the second request for linking to the bank.</em></p>

          <p>3. A list of the rights requested by Bank 131 will appear in the field below. Click the <strong>«Подтвердить»</strong> button.</p>

          <p>
            <picture>
              <source media="(max-width: 1064px)" srcset="/images/FAQ_2.jpg">
              <img alt="" src="/images/FAQ_2.jpg" srcset="/images/FAQ_2.jpg 1x, /images/FAQ_2@2x.jpg 2x" width="100%">
            </picture>
          </p>

          <p>4. Completed. Bank 131 is connected.</p>

          <p style="margin-bottom: 0">
            <picture>
              <source media="(max-width: 1064px)" srcset="/images/FAQ_3.jpg">
              <img alt="" src="/images/FAQ_3.jpg" srcset="/images/FAQ_3.jpg 1x, /images/FAQ_3@2x.jpg 2x" width="100%">
            </picture>
          </p>`,
        },
      ],
    },
    {
      title: '2. How to cancel the linking to Bank 131?',
      rectangle: [
        {
          description: `<p>Open your personal account «Мой налог», go to <strong>«Настройки»</strong>, the <strong>«Партнеры»</strong> section. Click the <strong>«Отключиться»</strong> button and confirm your decision.</p>

          <p>
            <picture>
              <source media="(max-width: 1064px)" srcset="/images/FAQ_4.jpg">
              <img alt="" src="/images/FAQ_4.jpg" srcset="/images/FAQ_4.jpg 1x, /images/FAQ_4@2x.jpg 2x" width="100%">
            </picture>
          </p>

          <p style="margin-bottom: 0">
            <picture>
              <source media="(max-width: 1064px)" srcset="/images/FAQ_5.jpg">
              <img alt="" src="/images/FAQ_5.jpg" srcset="/images/FAQ_5.jpg 1x, /images/FAQ_5@2x.jpg 2x" width="100%">
            </picture>
          </p>`,
        },
      ],
    },
    {
      title: '3. What should I do if there is no linking request or it has been canceled?',
      rectangle: [
        {
          description: '<p style="margin-bottom: 0">In this case, please contact the company that makes payments to you, with a request to send the second request for linking the INN.</p>',
        },
      ],
    },
    {
      title: '4. I got the check, but the company didn\'t make the payment. What should I do?',
      rectangle: [
        {
          description: '<p style="margin-bottom: 0">If the duplicate check appears in your profile, you can cancel it yourself. To do this, open your personal account «Мой налог» – section <strong>«Выручка»</strong> – <strong>«Чеки»</strong>. Select the desired check and cancel it. In the future, all such checks will be available in the <strong>«Состояние чека»</strong> tab – <strong>«Аннулированные»</strong>.</p>',
        },
      ],
    },
    {
      title: '5. How to link a card to Bank 131, change card details?',
      rectangle: [
        {
          description: '<p style="margin-bottom: 0">To change your payment details, please contact the company that makes payments to you.</p>',
        },
      ],
    },
  ],
};
