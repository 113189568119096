export default {
  seo: {
    title: 'FAQ | Банк 131 — вопросы и ответы',
    description: 'Ответы на популярные вопросы о продуктах банка'
  },
  title: 'FAQ',
  listFaq: [
    {
      title: '1. «Мой налог»: инструкция по привязке Банка 131',
      rectangle: [
        {
          description: `<p>Для подключения Банка 131 в «Мой налог» следуйте инструкции ниже:</p>

          <p>1. Откройте личный кабинет «Мой налог» на сайте или в приложении, перейдите в раздел <strong>«Настройки»</strong>, вкладка <strong>«Партнеры»</strong>.</p>

          <p>
            <picture>
              <source media="(max-width: 1064px)" srcset="/images/FAQ_1.jpg">
              <img alt="" src="/images/FAQ_1.jpg" srcset="/images/FAQ_1.jpg 1x, /images/FAQ_1@2x.jpg 2x" width="100%">
            </picture>
          </p>

          <p>2. Во вкладке «Партнеры» найдите Банк 131 и нажмите на кнопку <strong>«Предлагает подключиться»</strong>.</p>

          <p><em>В случае, если кнопка отсутствует, обратитесь в компанию, которая осуществляет вам выплаты, с просьбой отправить повторный запрос на привязку к банку.</em></p>

          <p>3. В поле ниже появится список прав, запрашиваеых Банком 131. Нажмите кнопку <strong>«Подтвердить»</strong>.</p>

          <p>
            <picture>
              <source media="(max-width: 1064px)" srcset="/images/FAQ_2.jpg">
              <img alt="" src="/images/FAQ_2.jpg" srcset="/images/FAQ_2.jpg 1x, /images/FAQ_2@2x.jpg 2x" width="100%">
            </picture>
          </p>

          <p>4. Готово. Банк 131 подключен.</p>

          <p style="margin-bottom: 0">
            <picture>
              <source media="(max-width: 1064px)" srcset="/images/FAQ_3.jpg">
              <img alt="" src="/images/FAQ_3.jpg" srcset="/images/FAQ_3.jpg 1x, /images/FAQ_3@2x.jpg 2x" width="100%">
            </picture>
          </p>`,
        },
      ],
    },
    {
      title: '2. Как отменить привязку к Банку 131?',
      rectangle: [
        {
          description: `<p>Откройте личный кабинет «Мой налог», перейдите в <strong>«Настройки»</strong>, раздел <strong>«Партнеры»</strong>. Нажмите кнопку <strong>«Отключиться»</strong> и подтвердите свое решение.</p>

          <p>
            <picture>
              <source media="(max-width: 1064px)" srcset="/images/FAQ_4.jpg">
              <img alt="" src="/images/FAQ_4.jpg" srcset="/images/FAQ_4.jpg 1x, /images/FAQ_4@2x.jpg 2x" width="100%">
            </picture>
          </p>

          <p style="margin-bottom: 0">
            <picture>
              <source media="(max-width: 1064px)" srcset="/images/FAQ_5.jpg">
              <img alt="" src="/images/FAQ_5.jpg" srcset="/images/FAQ_5.jpg 1x, /images/FAQ_5@2x.jpg 2x" width="100%">
            </picture>
          </p>`,
        },
      ],
    },
    {
      title: '3. Что делать, если запроса на привязку нет или его аннулировали?',
      rectangle: [
        {
          description: '<p style="margin-bottom: 0">В этом случае обратитесь, пожалуйста, к компании, которая отправляет вам выплаты, c просьбой отправить повторный запрос на привязку ИНН.</p>',
        },
      ],
    },
    {
      title: '4. У меня появился чек, но компания не сделала выплату. Что делать?',
      rectangle: [
        {
          description: '<p style="margin-bottom: 0">Если в профиле появился дубль чека, вы можете аннулировать его самостоятельно. Для этого откройте личный кабинет «Мой налог» – раздел <strong>«Выручка»</strong> – <strong>«Чеки»</strong>. Выберите нужный чек и аннулируйте его. В дальнейшем все такие чеки будут доступны во вкладке <strong>«Состояние чека»</strong> – <strong>«Аннулированные»</strong>.</p>',
        },
      ],
    },
    {
      title: '5. Как привязать карту к Банку 131, изменить данные карты?',
      rectangle: [
        {
          description: '<p style="margin-bottom: 0">Для изменения платежных данных, пожалуйста, обратитесь к компании, которая совершает вам выплаты.</p>',
        },
      ],
    },
    {
      title: '6. Я вижу незнакомые списания от Банка 131',
      rectangle: [
        {
          description: `<p>Банк 131 является провайдером, который помогает партнерам принимать платежи. Если вы заметили незнакомые списания от Банка 131, не переживайте – мы поможем разобраться.</p>

          <p><strong>Перед обращением в службу поддержки, проверьте следующее:</strong></p>
          
          <p>1. Убедитесь, что у вас нет <strong>активных подписок</strong> на сервисы, игры или связанные с играми услуги, настроенные вами или членами вашей семьи.</p>
          
          <p><em>Для остановки списаний отключите подписку на сервис. Если вы не помните, на какой сервис была подключена подписка, напишите в нашу службу поддержки.</em></p>
          
          <p>2. Проверьте, <strong>не совершали ли покупки</strong> с вашей карты члены семьи или лица, имеющие доступ к вашему платежному счету.</p>
          
          <p><em>В противном случае обратитесь в ваш банк-эмитент с заявлением об операции без согласия клиента.</em></p>
          
          <p>Если вы не нашли ответа на свой вопрос и не узнаете этот платеж, напишите в нашу службу поддержки <a href="mailto:help@131.ru">help@131.ru</a>. К письму приложите скриншот из вашего банка с данными об операции, на котором отражена сумма, дата и время списания. Это поможет быстрее разобраться в ситуации.</p>
          `,
        },
      ],
    },
    {
      title: '7. У меня открыт счет в Банке 131. Почему?',
      rectangle: [
        {
          description: `<p>Если вы физическое лицо или самозанятый и у вас открыт счет в Банке 131, на это может быть несколько причин.</p>

          <p><strong>Проверьте следующее:</strong></p>
          
          <p>1. Совершали ли вы денежный перевод с абонентского счета Теле2 на банковскую карту.</p>
          
          <p>В случае совершения денежного перевода с абонентского счета Теле2 на банковскую карту, открывается счет учета электронных денежных средств в Банке 131. Условия отправки денежного перевода регламентируются <a href="https://f.tele2.ru/offer/">Правилами пользования сервисом Теле2</a> и <a href="https://developer.131.ru/assets/tele2_public_offer_27-01-2022.pdf">Публичной офертой о заключении договора на оказание услуг по осуществлению переводов</a> Банка 131, с которыми соглашается каждый клиент перед совершением денежного перевода.</p>
          
          <p>Если у вас возникли вопросы по этому счету, напишите нам на <a href="mailto:tele2-claim@131.ru">tele2-claim@131.ru</a></p>
          
          <p>2. Зарегистрированы ли вы на платформе «Самозанятые.рф».</p>
          
          <p>При регистрации на сервисе «Самозанятые.рф» вы соглашаетесь с <a href="https://xn--80aapgyievp4gwb.xn--p1ai/legal/walletoffer.pdf">Публичной офертой на оказание услуг по осуществлению переводов</a>, в рамках которой с каждым совершеннолетним пользователем заключается договор на оказание услуг по осуществлению переводов, открывается электронный кошелек и счет для учета электронных денежных средств.</p>
          
          <p>Если у вас возникли вопросы по счету в рамках проекта «Самозанятые.рф», напишите нам на <a href="mailto:help@131.ru">help@131.ru</a></p>
          `,
        },
      ],
    },
  ],
};
