import React from 'react';

import Block from '../blocks/Block';
import BlockSection from '../blocks/BlockSection';
import OpeningRectangle from '../components/OpeningRectangle';
import SEO from '../components/seo';
import Title from '../components/typography/Title';
import enMessages from '../i18n/faq/en.js';
import ruMessages from '../i18n/faq/ru.js';
import Layout from '../layout/Layout';

const messages = {
  en: enMessages,
  ru: ruMessages,
};

const Faq = ({ pathContext: { locale } }) => {
  return (
    <Layout locale={locale}>
      <SEO
        description={messages[locale].seo.description}
        title={messages[locale].seo.title}
      />

      <Block
        className="Block_theme_about"
        config={{
          baseBorder: true,
        }}
      >
        <div className="container">
          <BlockSection>
            <Title
              className="Block-Title"
              headerStyleNumb="5"
              level="h1"
            >
              {messages[locale].title}
            </Title>

            <div className="Block-Text text4">
              {(messages[locale].listFaq || []).map((item, index) => {
                return (
                  <OpeningRectangle
                    key={index}
                    listRectangles={item.rectangle}
                    rectangleConfig={{
                      borderBottom: 'none',
                    }}
                    styleText="small"
                    title={item.title}
                  />
                );
              })}
            </div>
          </BlockSection>
        </div>
      </Block>
    </Layout>
  );
};

export default Faq;
