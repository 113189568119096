import React from 'react';
import PropTypes from 'prop-types';

import arrowDown from '../images/icons/buttons/arrow-down.svg'
import '../styles/components/openingRectangle.scss';

import Rectangle from './Rectangle';

const OpeningRectangle = (props) => {
  const {
    description = '',
    listRectangles = [],
    rectangleConfig = {},
    styleText = '',
    title = '',
  } = props;

  const [isOpened, setIsOpened] = React.useState(false);

  const handleToggle = React.useCallback(() => {
    setIsOpened((isOpenedPrev) => !isOpenedPrev);
  }, []);

  const classesText = React.useMemo(() => {
    let cls = 'Opening_rectangle-Text';

    if (styleText) {
      cls += ` Opening_rectangle-Text-${styleText}`;
    }

    if (rectangleConfig.borderBottom) {
      cls += ` Opening_rectangle-Text-Border-${rectangleConfig.borderBottom}`;
    }

    return cls;
  }, [styleText, rectangleConfig])

  return (
    <div className="Opening_rectangle">
      <div className="Opening_rectangle-Inner">
        <div
          className="Opening_rectangle-Cell"
          onClick={handleToggle}
        >
          <img
            className={`Opening_rectangle-Arrow_down ${isOpened ? 'open' : ''}`}
            src={arrowDown}
            alt=""
          />

          {title ? (
            <div
              className="Opening_rectangle-CellTitle"
              dangerouslySetInnerHTML={{__html: title}}
            />
          ) : null}

          <div className="Opening_rectangle-description">
            {description ? (
              <div dangerouslySetInnerHTML={{ __html: description }}/>
            ) : null}
          </div>
        </div>

        <div className={`${classesText} ${isOpened ? 'open' : ''}`}>
          {(listRectangles || []).map((item, index) => {
            return (
              <Rectangle
                key={`rectangle_${index}`}
                title={item.title}
                description={item.description}
                config={rectangleConfig}
                styleText={styleText}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const OpeningRectanglePropTypes = {
  description: PropTypes.string,
  listRectangles: PropTypes.array,
  title: PropTypes.string,
};

OpeningRectangle.propTypes = OpeningRectanglePropTypes;

export default OpeningRectangle;
